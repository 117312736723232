<template>
  <div class="wrapper">
    <Header />
    <div class="title">
      <img src="../../assets/image/zhantuanliebiao.png" alt="" />
    </div>
    <div class="content">
      <div class="item" v-for="item of merchantListData" :key="item.id">
        <div class="img">
          <img @click="handleJump(item.id)" :src="item.image" alt="" />
        </div>
        <div class="item-r">
          <div class="item-title">
            <span>{{ item.name }}</span>
            <span>展位号：TT888</span>
          </div>
          <div class="item-content" v-html="item.content"></div>
          <img @click="handleJump(item.id)" src="../../assets/image/more.png" alt="" />
        </div>
      </div>
    </div>
    <a-pagination class="pagination" v-model="currentPage" :hideOnSinglePage="true" :total="merchantListTotal" :defaultPageSize="10" @change="handleChange" />
    <Footer />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { post_merchant_list, IMAGE_URL } from '../../api'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      merchantListData: [],
      merchantListTotal: 0,
      currentPage: 1,
    }
  },
  mounted() {
    this.getMerchantList(1)
  },
  methods: {
    handleJump(id) {
      this.$router.push({
        path: '/details',
        query: { id, path: 1 },
      })
    },
    async getMerchantList(page) {
      const {
        data: { code, data },
      } = await post_merchant_list({ limit: 10, page, is_pavilion: 1 })
      if (code == 1) {
        this.merchantListTotal = data.total
        this.merchantListData = data.data.map((item) => ({
          ...item,
          image: IMAGE_URL + item.image,
          images: IMAGE_URL + item.images,
          logo: IMAGE_URL + item.logo,
          yyzz_images: IMAGE_URL + item.yyzz_images,
        }))
      } else {
        message.error('数据获取失败, 请重试')
      }
    },
    handleChange(index) {
      this.getMerchantList(index)
    },
  },
})
</script>

<style lang="less" scoped>
.wrapper {
  background: #fafafa;
  .pagination {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  .content {
    margin: 50px auto;
    width: 66%;
    max-width: 1240px;
    .item {
      margin-top: 20px;
      width: 100%;
      height: 340px;
      background: #fff;
      display: flex;
      padding: 20px;
      -moz-box-shadow: 0px 2px 5px #ddd;
      -webkit-box-shadow: 0px 2px 5px #ddd;
      box-shadow: 0px 2px 5px #ddd;
      transition: all 0.5s;
      &:hover {
        -moz-box-shadow: 0px 0px 15px #bbb;
        -webkit-box-shadow: 0px 0px 15px #bbb;
        box-shadow: 0px 0px 15px #bbb;
      }
      .item-r {
        margin-left: 30px;

        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        img {
          cursor: pointer;
          flex: 0 0 10%;
          width: 135px;
          // height: 40px;
        }
        .item-content {
          margin-top: -30px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .item-title {
          flex: 0 0 10%;
          span {
            display: block;
            &:nth-child(1) {
              color: #333;
              font-size: 22px;
            }
            &:nth-child(2) {
              margin-top: 2px;
              font-size: 12px;
              color: #9a9a9a;
            }
          }
        }
      }
      .img {
        flex: 0 0 400px;
        overflow: hidden;
        img {
          width: 100%;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .title {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}
</style>
